import styled from 'styled-components';
import React, { Component  } from "react";
import ContactForm from "../components/Form";
import photoMom from '../ressources/images/mom2.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import ChatIcon from '@material-ui/icons/Chat';
import Link from '@material-ui/core/Link';
import Politique from '../ressources/documents/Politique-de-protection-des-renseignements-personnels.pdf';

const Background = styled.footer`
text-align: center;
background-color: ${({ theme }) => theme.primaryDark};
padding: 20px;
color: white;
text-transform: none;
font-weight: normal;
margin: auto;
`; 

const Texte = styled.p`
 text-align: center;
 font-weight: normal;
 font-size: 1.2em;
 color: white;
`;

const Download = styled.a`
 text-align: center;
 font-weight: normal;
 font-size: 1.2em;
 color: white;
`;

const FlexContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
align-content: center;
padding: 0;
margin: 0;
list-style: none;
flex: 1;

@media only screen and (max-width: 767.98px){
    flex-wrap: nowrap;
    flex-flow: column;
}
`;

const Sections = styled.div`
font-weight: bolder;
padding: 5px;
width: 50vh;
margin-top: 0px;
line-height: 15px;
text-align: center;
align-self: center;

@media only screen and (max-width: 767.98px){
    margin: 0 auto;
}
`;

const AppImage = styled.img`
 height: 45vh;
 width: 45vh;
 padding: 20px;
 object-fit: contain;
 display: inline-block;
 border-radius: 7%;

`;


class Contact extends Component {
    render() {
        return (
            <Background>
                <FlexContainer>
                    <Sections>
                        <AppImage src={photoMom} alt="image" /> 
                    </Sections>
                    <Sections>
                        <Texte>Maryse Crête, B.A.A., FPAA</Texte>
                        <Texte>Consultante en gestion et ressources humaines</Texte>
                        <Texte>mcrete@servicesconseilspme.com</Texte>
                        <Texte>(581) 988-0466</Texte>
                        <Download href={Politique} download="politique-de-protection-des-renseignements-personnels.pdf">Politique de protection des renseignements personnels</Download>
                        <br />
                        <br />
                        <Link underline="hover" color="inherit" href="https://www.calendly.com/mcrete">Prendre rendez-vous</Link>
                    </Sections>
                    <Sections>
                        <Texte>Contactez-nous pour nous parler de votre projet!</Texte>
                        <ContactForm />
                     </Sections>
                </FlexContainer>
                <p>© Services Conseils PME, 2024</p>
                <Link href='https://www.facebook.com/Services-Conseils-PME-102747568075392'>
                    <FacebookIcon />
                </Link>
                <Link href='https://www.linkedin.com/company/42685211/'>
                    <LinkedInIcon />
                </Link>
                <Link href='mailto:mcrete@servicesconseilspme.com'>
                    <EmailIcon />
                </Link>
                <Link href='tel:+1 581 988-0466'>
                    <ChatIcon />
                </Link>
            </Background>
        );
    }
}

  export default Contact;