import styled from 'styled-components';
import React, { Component } from "react";
import logo from '../ressources/icons/Icon.png';
import { Fade } from 'react-reveal';
import imageAccueil from '../ressources/images/101620876_556006081771093_2190486784712900608_n.jpg';
import boutonDown from '../ressources/icons/arrow-down-1.png';
import { Parallax } from 'react-parallax';

const Background = styled.div`
text-align: center;
background-color: ${({ theme }) => theme.veryDark};
text-transform: none;
justify-content: center;
margin: auto;
color: ${({ theme }) => theme.veryDark};
`; 

const AppLogo = styled.img`
 height: 20vh;
 margin-top: 5vh;

 @media only screen and (max-width: 767.98px){
    height: 10vh;
}
`;

const FlexContainer = styled.div`
display: flex;
justify-content: center;
flex: 1;
align-content: center;
`;

const Sections = styled.div`
font-weight: bolder;
height: 100vh;
width: 60vw;
line-height: 50px;
text-align: center;
vertical-align: center;
align-self: center;
align-content: center;
@media only screen and (max-width: 767.98px){
    font-size: 1em;
    height: auto;
    width: 100vw;
}
`;

const Texte = styled.h3`
 text-align: center;
 font-weight: bolder;
 font-size: 2em;
`;

const PunchLine = styled.h3`
text-align: center;
font-weight: normal;
font-size: 1.5em;
`;

const Bouton = styled.button`
width: 49px;
height: 49px;
vertical-align: bottom;
border-radius: 100%;
-webkit-transition: background-color 0.25s ease-in-out;
transition: background-color 0.25s ease-in-out;
background: url(${boutonDown});
background-repeat:no-repeat;
bottom: 5vh;
margin: 5vh;
@media only screen and (max-width: 767.98px){
    display: none;
}
`;

class Accueil extends Component {

    toggleBouton(e) {
        window.scrollBy({ top: window.innerHeight, left: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <Background>                    
                <Parallax 
                    blur={10}
                    bgImage={imageAccueil}
                    bgImageAlt="accueil"
                    strength={200}>
                <FlexContainer>
                    <Fade>
                        <Sections>
                            <AppLogo src={logo} alt="logo" /> 
                            <Texte>Services-Conseils PME <br/>  Processus d'affaires et capital humain</Texte>
                            <hr className="solid" />
                            <PunchLine>Une gestion saine et efficace. Voilà comment Services-Conseils PME peut vous aider à améliorer votre rentabilité et motiver vos employés!</PunchLine>
                            <Bouton onClick={this.toggleBouton} />
                        </Sections>
                    </Fade>
                    </FlexContainer>
                </Parallax>
            </Background>
        );
    }
}

  export default Accueil;
