import styled from 'styled-components';
import React, { Component } from "react";
import { Slide } from 'react-reveal';
import { Parallax } from 'react-parallax';
import imageAccueil from '../ressources/images/iStock-1126856471.jpg';
import imageGrimpe from '../ressources/images/iStock-642445228.jpg';
import imagePuzzle from '../ressources/images/iStock-536659322.jpg';
import imageBoussole from '../ressources/images/iStock-685797114.jpg';
import imageEtoiles from '../ressources/images/100634155_239215857340484_8353680041753706496_n.jpg';
import imageTablette from '../ressources/images/100047806_333947884237841_7825940964664410112_n.jpg';
import imageHitech from '../ressources/images/99157676_283293162839265_245747201064566784_n.jpg';
import clickIcon from '../ressources/icons/fingerdown BL.png';

const Background = styled.div`
background-color: ${({ theme }) => theme.secondaryRed};
padding: 0px;
color: ${({ theme }) => theme.primaryLight};
text-transform: none;
margin: auto;

`; 

const Texte = styled.p`
 text-align: center;
 font-weight: normal;
 color: ${({ theme }) => theme.primaryLight};
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 font-size: 1.5em;
 margin: 0;
 padding-top: 3vh;
 `;

 const TextePointer = styled.p`
 text-align: center;
 font-weight: normal;
 color: ${({ theme }) => theme.primaryLight};
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 font-size: 1.5em;
 margin: 0;
 padding-top: 3vh;

 &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.veryDark};
  }
 `;

 const TexteHeader = styled.p`
 padding: 0px;
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 font-size: 2em;
 line-height: 40px;
 margin: 0;
 `;

const FlexContainer = styled.div`
display: flex;
flex-direction: column wrap;
justify-content: space-around;
padding: 0px;
margin: 0;
margin-top: 0;
list-style: none;
align-content: space-around;
align-items: center;
flex: 1;

@media only screen and (max-width: 767.98px){
    flex-wrap: nowrap;
    flex-flow: column-reverse;
    align-content: center;
    align-self: stretch;
    justify-content: center;
}
`;

const FlexContainerMobile = styled.div`
display: flex;
flex-direction: column wrap;
justify-content: space-around;
padding: 0px;
margin: 0;
margin-top: 0;
list-style: none;
align-content: space-around;
align-items: center;
flex: 1;

@media only screen and (max-width: 767.98px){
    flex-wrap: nowrap;
    flex-flow: column;
    align-content: center;
    align-self: stretch;
    justify-content: center;
}
`;

const Sections = styled.div`
font-weight: bolder;
padding: 0px;
line-height: 30px;
text-align: center;
width: 80%;
margin: 0;

@media only screen and (max-width: 767.98px){
    width: 100%;
    padding-top: 3vh;
}
`;

const Image = styled.img`
padding: 0px;
margin: 0px;
object-fit: contain;
max-width:100%;

@media only screen and (max-width: 767.98px){
    height: 60vh;
}
`;

const IconePointer = styled.img`
 height: 10vh;

 @media only screen and (max-width: 767.98px){
    margin-bottom: 3vh;
}

 &:hover {
    cursor: pointer;
}
`;

const ParallaxRemoved = styled.div`
margin: 0;
padding:0;
@media only screen and (max-width: 767.98px){
    display: none;
}
`;
const MobileVersion = (window.innerWidth >= 768) ? false : true;

export default class APropos extends Component {
    toggleBouton(e) {
        window.scrollBy({ top: window.innerHeight, left: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <Background>
                <ParallaxRemoved>
                    <Parallax 
                    strength={100}
                    bgImage={imageAccueil}
                    blur={{ min: -10, max: 15 }}
                    style={{height: '75vh'}}
                    disabled={MobileVersion} /></ParallaxRemoved>
                    <Slide left>
                    <FlexContainerMobile>
                        <Sections>
                            <Image src={imageGrimpe} alt="image" />
                        </Sections>
                        <Sections>
                            <TexteHeader>Voici quelques avantages de faire affaire avec nous&nbsp;:</TexteHeader>
                            <Texte>Notre offre s'adresse aux entreprises qui sont trop grosses pour pouvoir assurer la gestion et l'administration par eux-mêmes, mais trop petites pour embaucher une personne à temps complet pour faire le travail. Nous sommes disponibles à temps partiel, de façon occasionnelle ou permanente. Nous pouvons offrir des services à distance également. Nous avons envie de faire une différence pour votre entreprise!</Texte>
                        </Sections>
                    </FlexContainerMobile>
                    </Slide>
                    <Slide right>
                    <FlexContainer>
                        <Sections>
                            <TexteHeader>Main d'oeuvre spécialisée, compétente et de grande expérience pour répondre à vos besoins</TexteHeader>
                            <Texte>Une saine gestion peut vous aider à améliorer votre rentabilité et motiver vos employés.</Texte>
                        </Sections>
                        <Sections>
                            <Image src={imageBoussole} alt="image" />
                        </Sections>
                    </FlexContainer>
                    </Slide>
                    <Slide left>
                    <FlexContainerMobile>
                        <Sections>
                            <Image src={imageTablette} alt="image" />
                        </Sections>
                        <Sections>
                            <TexteHeader>Main d'oeuvre flexible et mobile, qui ne nécessite pas d'engagement sur du long terme</TexteHeader>
                            <Texte>Rentabilisez votre temps! Services-Conseils PME vous permettra de vous dégager relativement aux tâches administratives. Vous serez beaucoup plus rentables en vous concentrant sur ce que vous faites le mieux!</Texte>
                        </Sections>
                    </FlexContainerMobile>
                    </Slide>
                    <Slide right>
                    <FlexContainer>
                        <Sections>
                            <TexteHeader>Regard neuf par une personne indépendante</TexteHeader>
                            <Texte>Il est souvent beaucoup plus facile de trouver des solutions lorsqu’on provient de l’externe.</Texte>
                        </Sections>
                        <Sections>
                            <Image src={imagePuzzle} alt="image" />
                        </Sections>
                    </FlexContainer>
                    </Slide>
                    <Slide left>
                    <FlexContainerMobile>
                        <Sections>
                            <Image src={imageHitech} alt="image" />
                        </Sections>
                        <Sections>
                            <TexteHeader>Gestion de projet de bout en bout</TexteHeader>
                            <Texte>Nous sommes en mesure de vous supporter pour la gestion de vos projets, que ce soit les petits projets ou ceux d'envergure.</Texte>
                        </Sections>
                    </FlexContainerMobile>
                    </Slide>
                    <Slide right>
                    <FlexContainer>
                        <Sections>
                            <TexteHeader>Un service sur mesure au meilleur rapport qualité prix sur le marché</TexteHeader>
                            <TextePointer onClick={this.toggleBouton}>Le service que nous offrons est plus que compétitif. Contactez-nous pour connaître les forfaits disponibles.  </TextePointer>
                            <br />
                            <br />
                            <IconePointer src={clickIcon} onClick={this.toggleBouton} />
                        </Sections>
                        <Sections>
                            <Image src={imageEtoiles} alt="image" />
                        </Sections>
                    </FlexContainer>
                    </Slide>
            </Background>
        );
    }
}
