import React, { Component } from "react";
import { theme } from '../theme';
import { GlobalStyle } from '../global';
import { ThemeProvider } from 'styled-components';
import AffichageCarriere from '../components/carriere/AffichageCarriere';

class Carrieres extends Component {
    render() {
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                <GlobalStyle />
                    <AffichageCarriere />
                </ThemeProvider>
            </React.Fragment>
        )
    }
}

export default Carrieres;