import styled from 'styled-components';
import React, { Component } from "react";
import { Fade, Slide } from 'react-reveal';
import CarouselServices from '../components/CarouselServices.js'

const Background = styled.div`
text-align: center;
background-color: ${({ theme }) => theme.primaryDark};
padding: 0px;
color: white;
margin: auto;

`; 

const Header = styled.h1`
 font-weight: bolder;
 -webkit-text-stroke: 2px black;
 text-align: center;
 font-size: 3.2em;
 padding: 5vh;
 color: ${({ theme }) => theme.secondaryLight};
`;

const SectionMobile = styled.div`
padding: 25px;
@media only screen and (max-width: 767.98px){
}
`;


class NosServices extends Component {
    render() {
        return (
            <Background>
                <Slide left>
                    <Header>Nos services</Header>
                </Slide>
                <Fade>
                    <SectionMobile>
                        <CarouselServices />
                    </SectionMobile>
                </Fade>
            </Background>
        );
    }
}

  export default NosServices;
