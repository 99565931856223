import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import { GlobalStyle } from '../global';
import Accueil from './Accueil';
import APropos from './APropos';
import NosServices from './NosServices';

class MainPage extends Component {

    render() { 

        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                <GlobalStyle />
                    <Accueil />
                    <NosServices />
                    <APropos />
                </ThemeProvider>
            </React.Fragment>
        )
    }
}

export default MainPage;