import styled from 'styled-components';
import React, { Component } from "react";
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const BilletContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryLight};
  height: 30vh;
  margin: 30px;
  @include radius(5px);
  border-radius: 5px;

  @media only screen and (max-width: 767.98px){
    height: 65vh;
    width: 80vw;
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: space-between;

  @media only screen and (max-width: 767.98px){
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  padding-left: 3vw;

  @media only screen and (max-width: 767.98px){
    padding: 0;
    text-align: center;
    width: 80vw;
  }
`;

const Box = styled.div`
  width: 35vw;

  @media only screen and (max-width: 767.98px){
    margin: auto;
    margin-bottom: 20px;
  }

`;

const Date = styled.p`
  text-align: left;
  padding: 20px;
  color: ${({ theme }) => theme.darkGrey};
  font-weight: bold;
  padding-bottom: 0;

  @media only screen and (max-width: 767.98px){
    padding:0;
    text-align: center;
  }
`;

const Title = styled.h2`
  text-align: left;
  padding: 20px;
  padding-top: 0;

  @media only screen and (max-width: 767.98px){
    padding:0;
    text-align: center;
  }
`;

const Image = styled.img`
  padding: 0px;
  margin: 0px;
  object-fit: cover;
  height: 30vh;
  width: 20vw;

  @media only screen and (max-width: 767.98px){
    height: 30vh;
    width: 70vw;
  }
`;

const Bouton = styled.button`
  border: 1px solid black;
  padding: 10px 15px;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
  color: black;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background: white;
    border: 0px solid white;
    color: #0a4870;
  }

  @media only screen and (max-width: 767.98px){
    padding: 7px;
    font-size: 1em;
  }
`;

class Billet extends Component {
  render() {
      return (   
          <BilletContainer>
            <FlexContainer>
              <ContentContainer>
                <Date>
                  {this.props.date}
                </Date>
                <Title>
                  {this.props.title}
                </Title>
                <Box>
                  <Link component={RouterLink} to={'/'+this.props.id}>
                    <Bouton>
                      Consulter l'article
                    </Bouton>
                  </Link>
                </Box>
              </ContentContainer>
              <Link component={RouterLink} to={'/'+this.props.id}>
                <Image src={this.props.image} />
              </Link>
            </FlexContainer>
          </BilletContainer>
        )
    }
  }
  
  export default Billet;