import React, { Component } from "react";
import { theme } from '../../theme';
import { GlobalStyle } from '../../global';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import Antifragile from '../../ressources/images/antifragile.jpg';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const Background = styled.div`
    text-align: center;
    text-transform: none;
    justify-content: center;
    background-color: ${({ theme }) => theme.secondaryRed};
    margin: auto;
    color: ${({ theme }) => theme.veryDark};
    padding: 20px;
    margin: auto;
    width: 99vw;

    @media only screen and (max-width: 767.98px){
        width: 100vw;
        padding: 0;
    }
`; 

const Image = styled.img`
padding: 25px;
margin: 0px;
width: 40vw;

@media only screen and (max-width: 767.98px){
    width: 90vw;
}
`;

const TexteContainer = styled.div`
padding: 5vh;
width: 70vw;
background-color: white;
justify-content: center;
font-size: 1.2em;
    @media only screen and (max-width: 767.98px){
        width: 100vw;
        font-size: 1em;
    }
`;

const Texte = styled.p`
 text-align: center;
 font-weight: normal;
 font-size: 1em;
 color: ${({ theme }) => theme.veryDark};
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 margin: 0;
 text-align: justify;
 padding: 15px;

    @media only screen and (max-width: 767.98px){
        text-align: justify;
    }
 `;

 const Signature = styled.p`
 text-align: center;
 font-weight: normal;
 font-size: 1em;
 color: ${({ theme }) => theme.veryDark};
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 margin: 0;
 text-align: right;
 `;

 const TexteHeader = styled.p`
 font-size: 1.5em;
 padding: 10px;
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 line-height: 40px;
 margin: 0;
 color: ${({ theme }) => theme.veryDark};
 `;

const FlexContainer = styled.div`
display: flex;
flex-direction: row wrap;
justify-content: space-around;
padding: 0px;
margin: 0;
margin-top: 0;
list-style: none;
align-content: space-around;
align-items: center;
flex: 1;

@media only screen and (max-width: 767.98px){
    flex-wrap: nowrap;
    flex-flow: column-reverse;
    align-content: center;
    align-self: stretch;
    justify-content: center;
}
`;

const Sections = styled.div`
font-weight: bolder;
padding: 0px;
line-height: 30px;
text-align: center;
width: 80%;
margin: 0 auto;

@media only screen and (max-width: 767.98px){
    width: 100%;
}
`;

const Box = styled.div`
    padding: 2vw;
`;

const Bouton = styled.button`
  border: 1px solid black;
  padding: 10px 15px;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
  color: black;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background: white;
    border: 0px solid white;
    color: #0a4870;
  }

  @media only screen and (max-width: 767.98px){
    padding: 7px;
    font-size: 1em;
  }
`;

const Liste = styled.ul`
    text-align: left;
    font-weight: normal;
    font-family: Calibri Light;
    padding-left: 10vw;
    width: 80%
`;

class Page3 extends Component {
    render() {
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                <GlobalStyle />
                    <Background>
                        <FlexContainer>
                            <TexteContainer>
                                <Sections>
                                    <TexteHeader>Le modèle d’entreprise antifragile</TexteHeader>
                                </Sections>
                                <Sections>
                                    <Image src={Antifragile} alt="image" />
                                </Sections>
                                <Sections>
                                    <Texte>
                                      La fragilité se définit comme étant quelque chose « qui a un caractère précaire, vulnérable, faible et instable ». On pourrait croire que le contraire de fragile serait sûrement la robustesse, l’endurance, ou la résilience, mais en regardant la définition de ces termes, on peut constater qu’il ne s’agit pas d’antonymes à la fragilité. En effet, la résilience permet de faire face avec succès à une situation représentant un stress intense en raison de sa nocivité ou du risque qu’elle représente, ainsi qu'à se ressaisir, à s'adapter et à réussir à vivre et à se développer positivement en dépit de ces circonstances défavorables.  Le véritable contraire de la fragilité serait l’antifraglité, terme inventé par M. Nassim Nicholas Taleb, chercheur et philosophe. L’antifraglité aime l’incertitude, les évènements imprévisibles, les batailles, le chaos, les échecs et les erreurs, car elle lui offre des opportunités incroyables de dépassement, d’avancement et d’innovation! Dans le chaos, elle saisit les opportunités pour devenir de plus en plus forte, pour innover, pour se transformer.
                                    </Texte>
                                    <TexteHeader>
                                      Fragile  <ArrowRightAltIcon />  Robuste, endurant, résilient  <ArrowRightAltIcon />  Antifragile
                                    </TexteHeader>
                                    <Texte>
                                      L'antifragilité dans le contexte d’un modèle d’affaires d’entreprise pourrait se résumer comme la capacité pour les organisations à prospérer dans le chaos, l’adversité et les situations imprévisibles et hors de leur contrôle. Selon M. Taleb, professeur en ingénierie du risque, le désordre, le chaos et l’adversité procurent des bienfaits. Au lieu de se détruire dans la souffrance, elle se renforcit et s’améliore sous le coup des épreuves. Une entreprise ne peut évidemment pas tout prévoir les risques auxquels elle sera exposée. Cependant, elle peut adopter un modèle d’affaires bonifié afin de devenir plus forte en cas d’exposition à ces risques. Elle pourra ainsi retirer tous les bienfaits des évènements imprévisibles auxquels elle aura à faire face.
                                    </Texte>
                                    <Texte>
                                    L’antifragilité, adaptée à un modèle d’affaires, fera la différence entre une entreprise qui passe au travers d’une crise et une autre qui devra rendre les armes. La pandémie que nous vivons actuellement exerce un stress immense sur plusieurs entreprises. Il est vrai que cette situation est exceptionnelle, mais existe-t-il une façon de protéger les entreprises de situations imprévisibles qui risquent de les mettre en péril? Existe-t’il un modèle d’affaires plus fort et y a-t-il une façon de tirer profit des évènements imprévisibles? Mais quel est ce modèle d’affaires antifragile?
                                    </Texte>
                                    <TexteHeader>
                                      LE MODÈLE D’AFFAIRES ANTIFRAGILE
                                    </TexteHeader>
                                    <Texte>
                                    Les entreprises possédant un modèle d’affaires antifragile se distinguent par les caractéristiques suivantes :
                                    </Texte>
                                    <Liste>
                                      <li>Elles ont la capacité de revenir à un état initial après un traumatisme, même de pouvoir apprécier plus d’avantages que d’inconvénients;</li>
                                      <li>Elles sont innovantes et elles réussissent à s’adapter extrêmement rapidement aux évènements soudains et non prévisibles;</li>
                                      <li>Elles transforment la crise en système régénératif;</li>
                                      <li>Elles n’hésitent pas à transformer en profondeur leur organisation;</li>
                                      <li>Elles profitent du désordre et de la volatilité de la crise pour revoir le rôle des équipes et des technologies internes;</li>
                                      <li>Elles anticipent les évènements et cette anticipation fait partie d’un processus planifié par l’organisation. Que ce soit par des plans de continuité des affaires ou par des planifications stratégiques, les situations les plus improbables sont analysées en détails;</li>
                                      <li>Elles établissent une gestion des risques en établissant les plus grands facteurs de vulnérabilité, ainsi que les possibilités de rebond;</li>
                                      <li>Plutôt que de voir l’échec de façon péjorative, elles saisissent l’opportunité d’échec comme une formidable opportunité de faire progresser l’entreprise;</li>
                                      <li>Elles responsabilisent les employés en les impliquant dans la prise de décision (gestion décentralisée);</li>
                                      <li>Elles simplifient et assouplissent les processus d’affaires. Cela leur permet de pouvoir rapidement s’adapter et exercer des changements (agilité);</li>
                                      <li>Elles sont autonomes. Elles tentent le plus possible de réduire leur dépendance à des ressources externes;</li>
                                      <li>Elles diversifient leur offre;</li>
                                      <li>Elles créent des leaders au sein de leur organisation;</li>
                                      <li>Elles placent les technologies de l’information au centre de leurs préoccupations.</li>
                                    </Liste>
                                    <Texte>
                                    Ce modèle d’affaires permet aux entreprises de tirer profit des évènements imprévisibles auxquels elles doivent faire face. Non seulement, elles réussissent à avancer malgré les vents contraires, mais elles transforment l’adversité en réelle opportunité d’affaires.
                                    </Texte>
                                    <Texte>
                                    Le modèle d’affaires antifragile risque d’être très prometteur au cours des prochaines années afin de transformer les organisations et leur donner la structure afin de tirer profits des évènements imprévisibles. L’antifragilité est un modèle d’affaires innovant et extrêmement performant. Évidemment, chaque organisation a le loisir d’adapter son modèle d’affaires en fonction de son contexte et de ses processus d’affaires. Et malgré la situation exceptionnelle à laquelle les entrepreneurs sont confrontés, il n’est pas trop tard pour revoir leur modèle d’affaires afin de le rendre antifragile. N’hésitez pas à faire appel à un consultant afin d’examiner plus en profondeur comment ce modèle d’affaires pourrait être bénéfique pour votre entreprise.
                                    </Texte>
                                    <Signature>Maryse Crête, 16 janvier 2021</Signature>
                                </Sections>
                                <Box>
                                    <Link component={RouterLink} to={'/blogue'}>
                                        <Bouton>
                                            Retour au blogue
                                        </Bouton>
                                    </Link>
                                </Box>
                            </TexteContainer>
                        </FlexContainer>
                    </Background>
                    </ThemeProvider>
            </React.Fragment>
        )
    }
}

export default Page3;