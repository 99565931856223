import styled from 'styled-components';
import React, { Component } from "react";
import { theme } from '../../theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../../global';

const Background = styled.div`
    text-align: center;
    background-color: ${({ theme }) => theme.primaryRed};
    text-transform: none;
    color: ${({ theme }) => theme.veryDark};
    padding: 0px;
    margin: auto;

    @media only screen and (max-width: 767.98px){
        width: 100vw;
    }
`;

const OffreContainer = styled.div`
    font-size: 1.2em;
    padding: 10px;
    width: 80%;
    align-self: center;
    display: inline-block;
    font-family: Calibri Light;
    line-height: 40px;
    margin: 0;
    color: ${({ theme }) => theme.veryDark};
    background-color: white;
    margin: 20px;

    @media only screen and (max-width: 767.98px){
        width: 100vw;
        font-size: 1em;
        padding: 0;
        margin: 0;
    }
`;

const Title = styled.h1`
    text-transform: bold;
    text-transform: uppercase;
    padding: 0px;
    margin: auto;
    margin-top: 5vh;
`;

const SubTitle = styled.h2`
    text-transform: bold;
    text-transform: uppercase;
`;

const Paragraphe = styled.div`
    font-size: 0.9em;
    width: 60%;
    text-align: center;
    justify-content: center;
    margin: auto;

    @media only screen and (max-width: 767.98px){
        width: 80%;
        font-size: 1em;
    }
`;

const Description = styled.div`
    font-size: 1em;
    text-align: justify;
`;

const StyledBar = styled.hr`
    margin-top: 7vh;
    margin-bottom: 7vh;
`;

const Liste = styled.ul`
    text-align: left;
`;

const ModalDiv = styled.div`
    padding: 5vh;
`;

const Button = styled.button`
  border: 3px solid white;
  padding: 10px 15px;
  background: none;
  font-weight: bold;
  font-size: 1.3em;
  color: black;
  cursor: pointer;
  transition: .3s;
  
  &:hover {
    background: white;
    border: 0px solid white;
    color: #0a4870;
  }
`;

class Podiatre extends Component {
  render() {
      return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Background>
                    <OffreContainer>
                        <Title>Offre d'emploi</Title>
                        <SubTitle>Podiatre - Lévis et Montmagny</SubTitle>
                        <StyledBar className="solid" />
                        <Paragraphe>
                            <SubTitle>
                                Description du poste
                            </SubTitle>
                            <Description>
                                La Clinique Podiatrique Marie-Christine Bourque est présentement à la recherche d’un(e) podiatre pour se joindre à son équipe. La clinique est en pleine expansion, autant pour la succursale de Lévis, que pour celle de Montmagny, sur la rive-sud de Québec. La clientèle est très diversifiée et agréable à côtoyer. L’équipe est composée de trois (3) podiatres, une (1) infirmière en soins des pieds, deux (2) assistantes/réceptionnistes, et une proposée à l’entretien des lieux (COVID-19).
                            </Description>
                        </Paragraphe>
                        <Paragraphe>
                            <SubTitle>
                                Services offerts à la clinique
                            </SubTitle>
                            <Liste>
                                <li>Échographie;</li>
                                <li>Shockwave;</li>
                                <li>Laser thérapeutique;</li>
                                <li>Laser Cutera pour traitement des verrues plantaires;</li>
                                <li>Mésojet pour verrues résistantes;</li>
                                <li>Neurocryostimulation.</li>
                            </Liste>
                            <Paragraphe>
                                Vous disposez donc de plusieurs options de traitement à offrir aux clients.
                            </Paragraphe>
                        </Paragraphe>
                        <Paragraphe>
                            <SubTitle>
                                Profil recherché
                            </SubTitle>
                            <Liste>
                                <li>Détenir un doctorat de premier cycle en médecine podiatrique;</li>
                                <li>Être membre de l'Ordre des podiatres du Québec;</li>
                                <li>Déternir un permis de radiologie;</li>
                                <li>Être à l'aise à travailler en équipe;</li>
                                <li>Être une personne positive;</li>
                                <li>Capacité à offrir un excellent service à la clientèle;</li>
                                <li>Posséder un bon sens de l'organisation.</li>
                            </Liste>
                        </Paragraphe>
                        <Paragraphe>
                            <SubTitle>
                                Conditions de travail
                            </SubTitle>
                            <Liste>
                                <li>Poste à temps complet;</li>
                                <li>Salaire concurrentiel à discuter avec le ou la candidate;</li>
                                <li>Le ou la candidate retenu(e) devra être disponible pour travailler dans les 2 cliniques (Lévis et Montmagny). À cet effet, un dédommagement pour le déplacement sera alloué. Le temps de déplacement entre les deux (2) cliniques est d’environ 30 minutes.</li>
                            </Liste>
                        </Paragraphe>
                        <Paragraphe>
                            <SubTitle>
                                Avantages
                            </SubTitle>
                            <Liste>
                                <li>Diversité des traitements;</li>
                                <li>Équipements à la fine pointe de la technologie;</li>
                                <li>Belle ambiance de travail avec beaucoup de travail d'équipe;</li>
                                <li>Possibilité d'évoluer avec d'autres podiatres hautement qualifiés;</li>
                                <li>Rémunération concurentielle.</li>
                            </Liste>
                        </Paragraphe>
                        <Paragraphe>
                            <SubTitle>
                                Date d'entrée en fonction
                            </SubTitle>
                            <Paragraphe>
                                Le plus tôt possible
                            </Paragraphe>
                        </Paragraphe>
                        <ModalDiv>
                            <Button>Cliquez ici pour nous transmettre votre application par courriel!</Button>
                        </ModalDiv>
                    </OffreContainer>
                </Background>
            </ThemeProvider>
        </React.Fragment>
      )
  }
}

export default Podiatre;