import React, { Component } from "react";
import { theme } from '../../theme';
import { GlobalStyle } from '../../global';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import Courage from '../../ressources/images/courage.jpg';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const Background = styled.div`
    text-align: center;
    text-transform: none;
    justify-content: center;
    background-color: ${({ theme }) => theme.secondaryRed};
    margin: auto;
    color: ${({ theme }) => theme.veryDark};
    padding: 20px;
    margin: auto;
    width: 99vw;

    @media only screen and (max-width: 767.98px){
        width: 100vw;
        padding: 0;
    }
`; 

const Image = styled.img`
padding: 25px;
margin: 0px;
width: 40vw;

@media only screen and (max-width: 767.98px){
    width: 90vw;
}
`;

const TexteContainer = styled.div`
padding: 5vh;
width: 70vw;
background-color: white;
justify-content: center;
font-size: 1.2em;
    @media only screen and (max-width: 767.98px){
        width: 100vw;
        font-size: 1em;
    }
`;

const Texte = styled.p`
 text-align: center;
 font-weight: normal;
 font-size: 1em;
 color: ${({ theme }) => theme.veryDark};
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 margin: 0;
 text-align: justify;
 padding: 15px;

    @media only screen and (max-width: 767.98px){
        text-align: justify;
    }
 `;

 const Signature = styled.p`
 text-align: center;
 font-weight: normal;
 font-size: 1em;
 color: ${({ theme }) => theme.veryDark};
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 margin: 0;
 text-align: right;
 `;

 const TexteHeader = styled.p`
 font-size: 1.5em;
 padding: 10px;
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 line-height: 40px;
 margin: 0;
 color: ${({ theme }) => theme.veryDark};
 `;

const FlexContainer = styled.div`
display: flex;
flex-direction: row wrap;
justify-content: space-around;
padding: 0px;
margin: 0;
margin-top: 0;
list-style: none;
align-content: space-around;
align-items: center;
flex: 1;

@media only screen and (max-width: 767.98px){
    flex-wrap: nowrap;
    flex-flow: column-reverse;
    align-content: center;
    align-self: stretch;
    justify-content: center;
}
`;

const Sections = styled.div`
font-weight: bolder;
padding: 0px;
line-height: 30px;
text-align: center;
width: 80%;
margin: 0 auto;

@media only screen and (max-width: 767.98px){
    width: 100%;
}
`;

const Box = styled.div`
    padding: 2vw;
`;

const Bouton = styled.button`
  border: 1px solid black;
  padding: 10px 15px;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
  color: black;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background: white;
    border: 0px solid white;
    color: #0a4870;
  }

  @media only screen and (max-width: 767.98px){
    padding: 7px;
    font-size: 1em;
  }
`;

const Liste = styled.ul`
    text-align: left;
    font-weight: normal;
    font-family: Calibri Light;
    padding-left: 10vw;
`;

class Page2 extends Component {
    render() {
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                <GlobalStyle />
                    <Background>
                        <FlexContainer>
                            <TexteContainer>
                                <Sections>
                                    <TexteHeader>Le courage managérial</TexteHeader>
                                </Sections>
                                <Sections>
                                    <Image src={Courage} alt="image" />
                                </Sections>
                                <Sections>
                                    <Texte>
                                      Le courage managérial fait partie des compétences essentielles à tout bon gestionnaire. Comment définit-on le courage managérial? 
                                    </Texte>
                                    <Texte>
                                        Le courage managérial se caractérise par la capacité d’un gestionnaire à faire face à une situation difficile en surmontant ses peurs et en prenant des risques. C’est aussi sa capacité à prendre une situation en main plutôt que d’attendre, en espérant qu’elle se résolve par elle-même.
                                    </Texte>
                                    <Texte>
                                        La situation peut être difficile parce que la décision que le gestionnaire a à prendre n’est pas la plus populaire ou parce qu’elle affectera les membres de son équipe. 
                                        Parfois, ce sont ses convictions personnelles ou ses valeurs qui seront en conflit et qui viendront solliciter son courage managérial.
                                    </Texte>
                                    <Texte>
                                        Prendre des décisions sans avoir une garantie de résultat demande beaucoup de courage. Vivre la tourmente entourant cette prise de décision demande également beaucoup de courage. 
                                        Prendre en main la situation tout en se respectant en tant que gestionnaire demande énormément de courage. 
                                    </Texte>
                                    <Texte>
                                        Prendre en main la situation tout en se respectant en tant que gestionnaire demande énormément de courage. 
                                        Un gestionnaire qui possède du courage managérial démontrera les qualités suivantes :
                                    </Texte>
                                    <Liste>
                                        <li>Il n’aura pas peur de dire les choses;</li>
                                        <li>Il sera capable de dire non;</li>
                                        <li>Il respectera ses valeurs et les arrimera avec ses prises de décisions;</li>
                                        <li>Il défendra avec ardeur ses convictions;</li>
                                        <li>Il portera le changement au lieu de résister à celui-ci;</li>
                                        <li>Il reconnaîtra ses erreurs et démontrera de l’ouverture d’esprit.</li>
                                    </Liste>         
                                    <Texte>
                                        On utilise beaucoup le terme courage managérial en gestion, mais le courage managérial est également présent au quotidien dans nos vies. 
                                    </Texte>                       
                                    <Texte>
                                        C’est certes exigeant de prendre des décisions dans le cadre de notre travail ou de nos vies, mais soyons courageux et osons prendre action. C’est ainsi que les choses pourront changer et s’améliorer. Vous avez tout à y gagner, surtout le respect de vos employés ou de votre entourage. 
                                    </Texte>    
                                    <Signature>Maryse Crête, 1er novembre 2020</Signature>
                                </Sections>
                                <Box>
                                    <Link component={RouterLink} to={'/blogue'}>
                                        <Bouton>
                                            Retour au blogue
                                        </Bouton>
                                    </Link>
                                </Box>
                            </TexteContainer>
                        </FlexContainer>
                    </Background>
                    </ThemeProvider>
            </React.Fragment>
        )
    }
}

export default Page2;