import styled from 'styled-components';
import React, { Component } from "react";
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: auto;
  font-family: 'Sofia', sans-serif;
  @media only screen and (max-width: 767.98px){
    flex: 1 0 51%;
  }
`;

const Card = styled.div`
  background: white;
  position: relative;
  width: 18vw;
  display: flex;
  flex: 0 0 18vw;
  flex-direction: column;

  margin: 20px;
  margin-bottom: 30px;
  @include radius(10px);
  border-radius: 10px;
  overflow: hidden;

  background-position: center center;
  background-size: cover;

  text-align: center;
  color: #0a4870;

  transition: .3s;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    background: rgba(10,72,112, 0);
    
    transition: .3s;
  }

  &:hover {
    &::before {
      background: ${({ theme }) => theme.darkGrey};
      opacity: 0.6;
    }
  }

  @media only screen and (max-width: 767.98px){
    width: 80vw;
    flex: 0 0 80vw;
  }

`;

const BookContainer = styled.div`
  height: 20vh;

  @media only screen and (max-width: 767.98px){
    height: 15vh;
  }
`;

const BookContent = styled.div`
  position: relative;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  transition: .3s;
  transform: translateY(-200px);
    
  ${Card}:hover &{
    opacity: 1;
    transform: translateY(0px);
  }
`;

const InfoContainer = styled.div`
  flex: 1 0 auto;
  padding: 20px;
  background: #f0f0f0;
  transform: translateY(206px);
  transition: .3s;

  ${Card}:hover &  {      
    transform: translateY(0px);
  }
`;

const MoreInfo = styled.div`
  opacity: 1;    
`;

const MoreInfoContainer = styled.div`
  display: flex;
`;

const Box1 = styled.div`
  flex: 1 0;
  padding: 15px;
  margin-top: 20px;
  @include radius(10px);
  background: white;
  font-weight: bold;
  font-size: 0.9em;
`;

const Box2 = styled.div`
  flex: 1 0;
  padding: 15px;
  margin-top: 20px;
  @include radius(10px);
  background: white;
  font-weight: bold;
  font-size: 0.9em;
  color: #ec992c;
  margin-right: 10px;
`;

const Title = styled.h2`
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2em;

  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    width: 50px;
    margin: auto;
    background: #0a4870;
  }
`;

const IconContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.svg`
  margin-bottom: 5px;
  width: 24px;
  height: 24px;
`;

const Disclaimer = styled.p`
  margin-top: 20px;
  font-size: 0.8em;
  color: #7d7d7d;
`;

const Button = styled.button`
  border: 3px solid white;
  padding: 10px 15px;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
  color: white;
  cursor: pointer;
  transition: .3s;
  
  &:hover {
    background: white;
    border: 0px solid white;
    color: #0a4870;
  }
`;

const RedirectButton = styled.button`
  border: none;
  padding: 10px 15px;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
  color: black;
  cursor: pointer;
  transition: .3s;
  
  &:hover {
    color: #0a4870;
  }
`;

class Carriere extends Component {
  render() {
      return (
        <Container>
          <Card style={{
            backgroundColor: 'white',
            backgroundImage: `url(${this.props.image})`,
            backgroundSize: '80%',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: '25%'
          }}>
            <BookContainer>           
               <BookContent>
                  <Link href='mailto:mcrete@servicesconseilspme.com'>
                    <Button>
                      Postulez!
                    </Button>
                  </Link>
                </BookContent>
            </BookContainer>
            <InfoContainer>
              <Title>
                {this.props.title}
              </Title>
              <p>
                {this.props.location}
              </p>
              <Link component={RouterLink} to={'/'+this.props.id}>
                <RedirectButton>Consulter l'offre complète</RedirectButton>
              </Link>
              <MoreInfo>
                <MoreInfoContainer>
                  <Box1>
                    <IconContainer>
                      <Icon viewBox="0 0 24 24">
                        <path fill="currentColor" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />  
                      </Icon>
                    </IconContainer>
                    <span>{this.props.info}</span>
                  </Box1>
                  <Box2>
                    <IconContainer>
                      <Icon viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
                      </Icon>
                    </IconContainer>
                    <span>{this.props.date}</span>
                  </Box2>
                </MoreInfoContainer>
                <Disclaimer>{this.props.disclaimer}</Disclaimer>
              </MoreInfo>
            </InfoContainer>
          </Card>
        </Container>
      )
  }
}

export default Carriere;