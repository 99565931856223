import React from 'react';
import range from 'lodash/range';
import styled from 'styled-components';
import ItemsCarousel from 'react-items-carousel';
import imageDiagnostic from '../ressources/images/102418039_245987120178336_1675562743278600192_n.jpg';
import imageAmelioration from '../ressources/images/101932297_696433607838504_912223067663499264_n.jpg';
import imageGestion from '../ressources/images/101774636_681656359070168_7381719777208696832_n.jpg';
import imageCoaching from '../ressources/images/iStock-1205439297.jpg';
import imageDeveloppement from '../ressources/images/82295346_960802537711665_5799112139239063552_n.jpg';
import boutonLeft from '../ressources/icons/arrow left.png';
import boutonRight from '../ressources/icons/arrow right.png';

const noOfItems = 5;
const noOfCardsWeb = (window.innerWidth >= 1155) ? 3 : 1;
const autoPlayDelay = 10000;
const chevronWidth = 40;

const Wrapper = styled.div`
  padding: 0 ${chevronWidth}px;
  max-width: 80vw;
  margin: 0 auto;

  @media only screen and (max-width: 767.98px){
    max-width: 100vw;
  }
`;

const Images = [imageDiagnostic, imageAmelioration, imageGestion, imageCoaching, imageDeveloppement];

const Captions = ['Diagnostic organisationnel', "Amélioration des processus d'affaires", "Gestion de projets", "Coaching en gestion", "Développement du capital humain"];

const TexteCompl = ['Nous pouvons vous aider à poser un diagnostic organisationnel afin de solutionner des problématiques que vous vivez. Nous sommes en mesure de faire une conciliation entre les besoins des employés et les vôtres, pour atteindre une réussite commune!',
'Nous travaillons à partir de vos documents et nous les bonifions! L’objectif est de toujours trouver des solutions novatrices et applicables à votre entreprise qui donnent des résultats tangibles dans l’amélioration des processus d’affaires et les gains en efficacité organisationnelle.',
"Nous sommes en mesure de vous offrir la gestion globale de projets d’envergure, comme la gestion du changement, la migration de système ou autre projet requis pour vos opérations. Nous collaborons également avec plusieurs partenaires, si nécessaire, afin de pouvoir vous proposer une offre clef en main, laquelle pourra répondre entièrement à vos besoins.",
'Nous avons une approche professionnelle et personnalisée qui tient compte de votre situation et des facteurs concurrentiels. Notre bagage d’expérience nous permet de vous soutenir dans la prise de décisions managériales.',
"Nous offrons des conseils personnalisés, judicieux et adaptés pour des résultats incomparables en recrutement de personnel. Nous souhaitons vous aider à améliorer l’engagement des employés au travail, bonifier votre méthode d’évaluation de capital humain, vous fournir des plans de formation adaptés pour les employés, etc. Nous pouvons aussi vous aider à développer des outils afin de vous soutenir sur du long terme, tels qu'une politique de télétravail ou de ressources humaines."]

const SlideItem = styled.div`
  height: 80vh;
  margin: auto;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: black;
  background-color: white;
  background-image: url(${props => props.image});
  border-radius: 25px 0px 25px 0px;
  position: relative;
  font-family: Calibri Light;

`;

const Image = styled.img`
display: block;
object-fit: contain;
width: 100%;
height: auto;
}
`;

const Overlay = styled.div`
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
height: 100%;
width: 100%;
opacity: 0;
transition: .5s ease;
background-color: #008CBA;
border-radius: 25px 0px 25px 0px;
  &:hover {
    opacity: 1;
  }
}
`;

const Texte = styled.p`
color: white;
position: absolute;
font-weight: normal;
text-transform: none;
font-size: 1.5em;
top: 50%;
left: 50%;
width: 90%;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
text-align: center;

@media only screen and (max-width: 767.98px){
  font-size: 1em;
}
`;

const Header = styled.p`
position: absolute;
font-size: 1.8em;
text-transform: uppercase;
top: 10%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}
`;

const BoutonLeft = styled.button`
width: 49px;
height: 49px;
border: none;
-webkit-transition: background-color 0.25s ease-in-out;
transition: background-color 0.25s ease-in-out;
background: url(${boutonLeft});
background-repeat:no-repeat;
&:hover {
    cursor: pointer;
   }
`;

const BoutonRight = styled.button`
width: 49px;
height: 49px;
border: none;
-webkit-transition: background-color 0.25s ease-in-out;
transition: background-color 0.25s ease-in-out;
background: url(${boutonRight});
background-repeat:no-repeat;
&:hover {
    cursor: pointer;
   }
`;

const carouselItems = range(noOfItems).map(index => (
  <SlideItem image={Images[index]}>
    <Header>{Captions[index]}</Header>
    <Image src={Images[index]} />
    <Overlay>
      <Texte>{TexteCompl[index]}</Texte>
    </Overlay>
  </SlideItem>
));

export default class CarouselServices extends React.Component {
  state = {
    activeItemIndex: 0,
  };

  componentDidMount() {
    this.interval = setInterval(this.tick, autoPlayDelay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => this.setState(prevState => ({
    activeItemIndex: (prevState.activeItemIndex + 1) % (noOfItems-noOfCardsWeb + 1),
  }));

  onChange = value => this.setState({ activeItemIndex: value });

  render() {
    return (
      <Wrapper>
        <ItemsCarousel
          infiniteLoop
          gutter={12}
          numberOfCards={noOfCardsWeb}
          activeItemIndex={this.state.activeItemIndex}
          requestToChangeActive={this.onChange}
          rightChevron={<BoutonRight></BoutonRight>}
          leftChevron={<BoutonLeft></BoutonLeft>}
          chevronWidth={chevronWidth}
          outsideChevron
          children={carouselItems}
        />
      </Wrapper>
    );
  }
}
