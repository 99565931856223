import React from 'react';
import MainPage from './pages/MainPage';
import Blog from './pages/Blog'
import Carrieres from './pages/Carrieres';
import Contact from './pages/Contact';
import NotFound from './pages/404';
import Entrepreneurs from './pages/blog/blog1.js';
import Courage from './pages/blog/blog2.js';
import Antifragile from './pages/blog/blog3.js';
import Podiatre from './pages/affichages/Podiatre.js';
import NavBar from './components/NavBar';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { GlobalStyle } from './global';

export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <main>
            <Switch>
                <Route exact path="/" component={MainPage} />
                <Route component={NotFound} / >
            </Switch>
        </main>
        <Contact />
    </Router>  
  </ThemeProvider>
  );
}
