import React, { Component } from "react";
import { theme } from '../theme';
import { GlobalStyle } from '../global';
import { ThemeProvider } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import styled from 'styled-components';
import Billet from '../components/Billet'
import Balumière from '../ressources/images/balumière.jpg';
import Courage from '../ressources/images/courage.jpg';
import Antifragile from '../ressources/images/antifragile.jpg';

const Background = styled.div`
    text-align: center;
    text-transform: none;
    justify-content: center;
    background-color: ${({ theme }) => theme.secondaryRed};
    margin: auto;
    width: 99vw;
    color: ${({ theme }) => theme.veryDark};
    padding: 20px;

    @media only screen and (max-width: 767.98px){
        width: 100vw;
        padding: 0;
      }
`; 

const ListeContainer = styled.div`
    background-color: #F2F2F2;
    padding: 10px;
    text-align: center;
    @media only screen and (max-width: 767.98px){
        width: 100vw;
        padding: 0;
        justify-content: center;
      }
`;

const DivContainer = styled.div`
    padding-top: 2vh;
    padding-left: 15vw;
    padding-right: 15vw;
    padding-bottom: 2vh;

    @media only screen and (max-width: 767.98px){
        padding-left: 10vw;
        padding-right: 0;
      }
    
`;

const Title = styled.h2`
    text-align: left;
`;

class Blog extends Component {
    render() {
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <Background>
                        <ListeContainer>
                            <DivContainer>
                                <Title>Publications récentes:</Title>
                                <Billet 
                                title={'Entrepreneurs, mettez-vous en lumière!'} 
                                date={'18 octobre 2020'} 
                                subtitle={"L'importance de savoir déléguer."}
                                image={Balumière}
                                id={'entrepreneurs-mettez-vous-en-lumiere'}
                                />
                                <Billet 
                                title={'Le courage managérial'} 
                                date={'1er novembre 2020'} 
                                subtitle={"Et vous, êtes-vous courageux(se)?"}
                                image={Courage}
                                id={'le-courage-managerial'}
                                />
                                <Billet 
                                title={"Le modèle d'affaires antifragile"} 
                                date={'16 janvier 2021'} 
                                subtitle={"La fragilité se définit comme étant quelque chose « qui a un caractère précaire, vulnérable, faible et instable ». On pourrait croire que..."}
                                image={Antifragile}
                                id={'le-modele-d-affaires-antifragile'}
                                />
                            </DivContainer>
                        </ListeContainer>
                    </Background>
                </ThemeProvider>
            </React.Fragment>
        )
    }
}

export default Blog;