import styled from 'styled-components';
import React, { Component } from "react";
import Carriere from './Carriere.js';
import MCB from '../../ressources/icons/MCB.png';
import SCPME from '../../ressources/icons/Icon.png';
import MichaudLeBel from '../../ressources/icons/michaudlebel.png';
import SainteJulie from '../../ressources/icons/LogoSainteJulie.png';
import Link from '@material-ui/core/Link';

const Background = styled.div`
  text-align: center;
  text-transform: none;
  justify-content: center;
  background-color: ${({ theme }) => theme.secondaryRed};
  color: ${({ theme }) => theme.veryDark};
  padding: 20px;
  margin: auto;
  width: 99vw;

  @media only screen and (max-width: 767.98px){
    width: 100vw;
  }
`; 

const ListeAffichage = styled.div`
  display: flex;
  flex-direction: row wrap;
  flex-wrap: wrap;
  align-content: space-evenly;
  justify-content: space-evenly;
  margin: auto;
`;

const Title = styled.h1`
  text-align: center;
  text-transform: none;
  font-weight: bolder;
  font-size: 2em;
`;

class AffichageCarriere extends Component {
  render() {
      return (
        <Background>   
          <Title>Consultez ici les offres d'emploi disponibles!</Title>
          <ListeAffichage>
            <Carriere 
            title={'Podiatre'} 
            location={'Lévis/Montmagny'} 
            id={'podiatre-levis'} 
            image={MCB}
            info={'Temps plein'}
            date={'Le plus tôt possible'}
            disclaimer={'Clinique Podiatrique Marie-Christine Bourque'}
            />
          </ListeAffichage>   
          Pas d'emploi pour votre profil en ce moment? <Link href='mailto:mcrete@servicesconseilspme.com'>Cliquez ici</Link> pour nous transmettre une candidature et nous vous contacterons si un poste s'affiche pour vous!
        </Background>
      )
  }
}

export default AffichageCarriere;