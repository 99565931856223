export const theme = {
    veryDark: '#0a0505',
    primaryDark: '#333333',
    secondaryDark: '#908A89',
    primaryLight: '#EFFFFA',
    secondaryLight: 'b40000',
    primaryRed: '#b32400',
    secondaryRed: '#990000',
    primaryHover: '#343078',
    softGrey: '#C0C0C0',
    darkGrey: '#545454',
    mobile: '576px',
  }