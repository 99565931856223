import styled, { css } from 'styled-components';
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import Tooltip from 'react-power-tooltip';

const TooltipContainer = styled.div`
position: relative;
font-family: Calibri Light;
font-size: 0.5em;
color: black;
margin: 0;
padding: 0;
`;

const Input = styled(Field)`
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1rem;
  font-style: normal;
  font-weight: 300;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.75rem 0.75rem;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px, rgb(227, 230, 232) 0px 0px 0px
        3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }

  /* Autocomplete styles in Chrome*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    css`
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px, rgb(177, 247, 160) 0px 0px
            0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px, rgb(251, 178, 174) 0px 0px
            0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`;

const StyledInlineErrorMessage = styled.div`
font-size: 0.4em;
font-family: Calibri Light;
position: absolute;
color: #fff;
background: #282828;
padding: 8px 12px;
right: 0;
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
max-width: 200px;
pointer-events: none;
border-radius: 4px;
transform: translate3d(0, -100%, 0);
transition: all .3s ease;
transition-delay: 1.2s;
`;

const Submit = styled.button`
  width: 100%;
  margin-top: 1.5rem;

  background-color: rgb(24, 81, 187);
  display: block;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 700;
  height: 3rem;
  white-space: nowrap;
  color: rgb(232, 243, 255) !important;
  padding: 0.5rem 1rem;

  &:active,
  &:focus,
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: pointer;
    background-color: rgb(163, 168, 173);
    box-shadow: none;
    color: rgb(255, 255, 255) !important;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
`;

const StyledSelect = styled.select`
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1rem;
  font-style: normal;
  font-weight: 300;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.75rem 0.75rem;
  word-break: break-word;
`;

const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <StyledSelect {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledInlineErrorMessage>{meta.error}</StyledInlineErrorMessage>
      ) : null}
    </>
  );
};

const ContactForm = () => {
  const [formValues, setFormValues] = useState();
  const [emailSent, setEmailSent] = useState(false);

        return (
            <Formik
                initialValues={{
                fullname: "",
                telephone: "",
                email: "",
                service: "",
                infos: "",
                }}
                validationSchema={Yup.object().shape({
                fullname: Yup.string()
                    .required("Veuillez svp saisir votre nom"),
                telephone: Yup.string()
                    .required("Veuillez svp saisir votre numéro de téléphone"),
                email: Yup.string()
                    .email("Le courriel est invalide")
                    .required("Veuillez svp saisir votre courriel"),
                infos: Yup.string(),
                })}

                onSubmit={(values, actions) => {
                  console.log(values);
                  setFormValues(values);

                  const timeOut = setTimeout(() => {
                    emailjs.send(
                      "default_service", //Email service as defined in EmailJS setting
                      "template_hQZMvCRF", // Email template ID provided by EmailJS
                      {
                        from_name: JSON.stringify(values.fullname),
                        to_name: "mcrete@servicesconseilspme.com",
                        reply_to: JSON.stringify(values.email),
                        telephone_number: JSON.stringify(values.telephone),
                        service_required: JSON.stringify(values.service),
                        message_html: JSON.stringify(values.infos),
                      },
                      "user_dzVez3EHzHLTSl9cRTogu" // EmailJS user ID
                    )
                    .then(() => {
                        setEmailSent(true)
                        actions.setSubmitting(false)
                        actions.resetForm()
                        alert('Votre demande a été transmise avec succès.')
                      })
                    .catch(() => {
                        actions.setSubmitting(false)
                        alert('Un problème est survenu. Veuillez réessayer plus tard.')
                      })
                  }, 1000)
                }}
              >
                {({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                isValidating,
                isValid
                }) => {
                return (
                    <>
                    <Form name="contact" method="post" onSubmit={handleSubmit}>
                    <label htmlFor="fullname">
                        <Input
                        type="text"
                        name="fullname"
                        autoComplete="name"
                        placeholder="Veuillez saisir votre nom"
                        valid={touched.fullname && !errors.fullname}
                        error={touched.fullname && errors.fullname}
                        />
                    </label>
                    {errors.fullname && touched.fullname && (
                      <TooltipContainer>
                        <Tooltip show={true}
                               arrowAlign="center"
                               position="bottom left"
                               animation="bounce"
                               alert="rgb(255, 0, 0)"
                               static>
                                 <span>{errors.fullname}</span>
                        </Tooltip>
                      </TooltipContainer>
                    )}
                    <label htmlFor="telephone">
                        <Input
                        name="telephone"
                        autoComplete="telephone"
                        placeholder="Veuillez saisir votre numéro de téléphone"
                        valid={touched.telephone && !errors.telephone}
                        error={touched.telephone && errors.telephone}
                        />
                    </label>
                    {errors.telephone && touched.telephone && (
                      <TooltipContainer>
                        <Tooltip show={true}
                                 arrowAlign="center"
                                 position="bottom left"
                                animation="bounce"
                                alert="rgb(255, 0, 0)"
                                static>
                                  <span>{errors.telephone}</span>
                        </Tooltip>
                    </TooltipContainer>
                    )}
                    <label htmlFor="email">
                        <Input
                        type="email"
                        name="email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        placeholder="Veuillez saisir votre courriel"
                        valid={touched.email && !errors.email}
                        error={touched.email && errors.email}
                        />
                    </label>
                    <ErrorMessage name="email">
                    {(msg) => (
                        <TooltipContainer>
                          <Tooltip show={true}
                                  arrowAlign="center"
                                  position="bottom left"
                                  animation="bounce"
                                  alert="rgb(255, 0, 0)"
                                  static>
                                    <span>{msg}</span>
                          </Tooltip>
                      </TooltipContainer>
                    )}
                    </ErrorMessage>
                    <label htmlFor="service">
                      <MySelect label="Besoin" name="service">
                        <option value="">Veuillez sélectionner le service désiré</option>
                        <option value="diagnostic">Diagnostic organisationnel</option>
                        <option value="amelioration">Amélioration des processus d'affaires</option>
                        <option value="coaching">Coaching en gestion</option>
                        <option value="developpement">Développement du capital humain</option>
                        <option value="projets">Gestion de projets</option>
                        <option value="Autre">Autre</option>
                      </MySelect>
                    </label>
                    <label htmlFor="infos">
                        <Input
                        type="text"
                        name="infos"
                        autoComplete="infos"
                        placeholder="Autres informations (facultatif)"
                        valid={touched.infos && !errors.infos}
                        error={touched.infos && errors.infos}
                        />
                    </label>
                    <Submit type="submit" disabled={!isValid || isSubmitting}>
                        {isSubmitting ? `Envoi...` : `Envoyer`}
                    </Submit>
                    </Form>
                </>
                );
                }}
            </Formik>
            );
        }

export default ContactForm;