import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import logo from '../ressources/icons/Complete logo.png';
import styled from 'styled-components';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { theme } from '../theme';

const IconePointer = styled.img`
    height: 5vh;
`;


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const StyledBar = withStyles({
  root: {
    background: '#f3f0f1',
    box: '-6px -6px 10px rgba(255, 255, 255, 0.8), 6px 6px 10px rgba(0, 0, 0, 0.2)',
    color: theme.darkGrey,
  }
})(AppBar);

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

export default function NavBar(props) {

    const width = window.innerWidth;
    const isMobile = (width <= 767.98);

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    if (!isMobile){
        return (
            <HideOnScroll {...props}>
                <StyledBar position="sticky">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <Link 
                        component={RouterLink} 
                        to="/" 
                        color='inherit'>
                          <IconePointer src={logo} />
                        </Link>
                    </IconButton>
                    <Typography edge="end" className={classes.title} variant="h6" noWrap>
                        <Link 
                        component={RouterLink} 
                        to="/" 
                        color='inherit'>
                            Qui sommes-nous?
                        </Link>
                    </Typography>
                    <Typography edge="end" className={classes.title} variant="h6" noWrap>
                        <Link 
                        component={RouterLink} 
                        to="/carrieres"
                        color='inherit'
                        >
                            Carrières
                        </Link>
                    </Typography>
                    <Typography edge="end" className={classes.title} variant="h6" noWrap>
                        <Link 
                        component={RouterLink} 
                        to="/blogue"
                        color='inherit'
                        >
                            Blogue
                        </Link>
                    </Typography>
                    <Typography edge="end" className={classes.title} variant="h6" noWrap>
                        <Link 
                        component={RouterLink} 
                        to={{ pathname:"https://www.calendly.com/mcrete" }}
                        target="_blank"
                        color='inherit'
                        >
                            Prendre rendez-vous
                        </Link>
                    </Typography>
                </Toolbar>
                </StyledBar>
            </HideOnScroll>
            );
      }
    else {
      return (
        <HideOnScroll {...props}>
         <div className={classes.root}>
          <StyledBar position="static">
            <Toolbar>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <IconePointer src={logo} />
              </IconButton>
              <Typography variant="h6" className={classes.title} />
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <Link component={RouterLink} to="/">
                        Qui sommes-nous?
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Link component={RouterLink} to="/carrieres">
                        Carrières
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Link component={RouterLink} to="/blogue">
                        Blogue
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Link component={RouterLink} to={{ pathname:"https://www.calendly.com/mcrete" }}
                        target="_blank">
                        Prendre rendez-vous
                      </Link>
                    </MenuItem>
                  </Menu>
                </div>
            </Toolbar>
          </StyledBar>
        </div>
        </HideOnScroll>
      )
    }
  }
    