import React, { Component } from "react";
import { theme } from '../../theme';
import { GlobalStyle } from '../../global';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import balumière from '../../ressources/images/balumière.jpg';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const Background = styled.div`
    text-align: center;
    text-transform: none;
    justify-content: center;
    background-color: ${({ theme }) => theme.secondaryRed};
    margin: auto;
    color: ${({ theme }) => theme.veryDark};
    padding: 20px;
    margin: auto;
    width: 99vw;

    @media only screen and (max-width: 767.98px){
        width: 100vw;
        padding: 0;
    }
`; 

const Image = styled.img`
padding: 25px;
margin: 0px;
width: 40vw;

@media only screen and (max-width: 767.98px){
    width: 90vw;
}
`;

const TexteContainer = styled.div`
padding: 5vh;
width: 70vw;
background-color: white;
justify-content: center;
font-size: 1.2em;
    @media only screen and (max-width: 767.98px){
        width: 100vw;
        font-size: 1em;
    }
`;

const Texte = styled.p`
 text-align: center;
 font-weight: normal;
 font-size: 1em;
 color: ${({ theme }) => theme.veryDark};
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 margin: 0;
 text-align: justify;
 padding: 15px;

    @media only screen and (max-width: 767.98px){
        text-align: justify;
    }
 `;

 const Signature = styled.p`
 text-align: center;
 font-weight: normal;
 font-size: 1em;
 color: ${({ theme }) => theme.veryDark};
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 margin: 0;
 text-align: right;
 `;

 const TexteHeader = styled.p`
 font-size: 1.5em;
 padding: 10px;
 width: 80%;
 align-self: center;
 display: inline-block;
 font-family: Calibri Light;
 line-height: 40px;
 margin: 0;
 color: ${({ theme }) => theme.veryDark};
 `;

const FlexContainer = styled.div`
display: flex;
flex-direction: row wrap;
justify-content: space-around;
padding: 0px;
margin: 0;
margin-top: 0;
list-style: none;
align-content: space-around;
align-items: center;
flex: 1;

@media only screen and (max-width: 767.98px){
    flex-wrap: nowrap;
    flex-flow: column-reverse;
    align-content: center;
    align-self: stretch;
    justify-content: center;
}
`;

const Sections = styled.div`
font-weight: bolder;
padding: 0px;
line-height: 30px;
text-align: center;
width: 80%;
margin: 0 auto;

@media only screen and (max-width: 767.98px){
    width: 100%;
}
`;

const Box = styled.div`
    padding: 2vw;
`;

const Bouton = styled.button`
  border: 1px solid black;
  padding: 10px 15px;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
  color: black;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background: white;
    border: 0px solid white;
    color: #0a4870;
  }

  @media only screen and (max-width: 767.98px){
    padding: 7px;
    font-size: 1em;
  }
`;

class Page1 extends Component {
    render() {
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                <GlobalStyle />
                    <Background>
                        <FlexContainer>
                            <TexteContainer>
                                <Sections>
                                    <TexteHeader>Entrepreneurs, mettez-vous en lumière!</TexteHeader>
                                </Sections>
                                <Sections>
                                    <Image src={balumière} alt="image" />
                                </Sections>
                                <Sections>
                                    <Texte>
                                        Lorsqu’on est entrepreneur, il est parfois difficile de déléguer sa gestion à quelqu’un d’autre. Dès que l’entreprise connaît du succès, il devient rapidement ardu de concilier la gestion et les opérations quotidiennes. Les entrepreneurs qui ont peu de soutien administratif doivent ainsi régulièrement investir beaucoup de temps, en sus de leur travail régulier, pour s’occuper de la gestion de leur entreprise.
                                        Avant d’aborder les avantages à confier la gestion à un spécialiste, l’entrepreneur pourrait évaluer son niveau de confort et de bonheur lorsqu’il consacre du temps en gestion. Si l’entrepreneur apprécie ces tâches et qu’il réussit bien à concilier le tout avec l’ensemble de ses responsabilités, alors pas de soucis. Si au contraire il n’est pas confortable avec ces tâches ou si celles-ci finissent par lui demander trop de temps, il est peut-être temps pour lui de songer à les déléguer à un spécialiste. 
                                        Et dans son équation, l’entrepreneur devrait calculer l’écart entre son propre tarif horaire et celui du spécialiste en gestion. Il est fort à parier que l’économie monétaire sera évidente et ce, sans compter l’économie d’énergie dont il pourra bénéficier!
                                    </Texte>
                                    <Texte>
                                        Aussi, le spécialiste en gestion s’acquittera probablement des tâches qui lui auraient été déléguées beaucoup plus rapidement, compte tenu qu’elles font partie de son champ de compétences. Ce spécialiste pourra de plus porter un regard neuf aux situations, ce qui risque d’apporter d’autres solutions aux éventuels problèmes rencontrés. Finalement, les employés pourront bénéficier de l’avantage de pouvoir se référer à une personne compétente en ce qui a trait à leurs questions de gestion ou ressources humaines.
                                        Être gestionnaire est un métier en soi. Plusieurs entrepreneurs possèdent d’ailleurs d’excellentes qualités de gestionnaire et s’y trouvent heureux dans ce type de tâches. Si tel n’est pas votre cas ou si vous êtes débordés, n’hésitez pas à faire appel à un spécialiste en gestion lequel pourra vous supporter dans le développement de votre entreprise.
                                        Allez, mettez-vous en lumière dès maintenant et faites le choix de vous concentrer sur ce que vous le mieux, développer votre entreprise !
                                    </Texte>
                                    <Signature>Maryse Crête, 18 octobre 2020</Signature>
                                </Sections>
                                <Box>
                                    <Link component={RouterLink} to={'/blogue'}>
                                        <Bouton>
                                            Retour au blogue
                                        </Bouton>
                                    </Link>
                                </Box>
                            </TexteContainer>
                        </FlexContainer>
                    </Background>
                    </ThemeProvider>
            </React.Fragment>
        )
    }
}

export default Page1;