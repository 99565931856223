import React, { Component } from "react";
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../theme';
import { GlobalStyle } from '../global';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import OmbilicalCord from '../ressources/images/131490897_718329725464381_5161286122015517040_n.jpg'
import { Parallax } from 'react-parallax';

const Background = styled.div`
  text-align: center;
  text-transform: none;
  margin: auto;
  color: ${({ theme }) => theme.veryDark};
  height: 90vh;
  width: 100vw;
`; 

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  margin: 0;
  margin-top: 0;
  list-style: none;
  align-content: space-between;
  align-items: center;
  margin-top: 5vh;
`;

const PageElement = styled.div`
  padding: 15px;
  font-size: 1.5em;
  text-transform: bold;
`;



class NotFound extends Component {
  render() {
      return (
          <React.Fragment>
              <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Parallax 
                    blur={1}
                    bgImage={OmbilicalCord}
                    bgImageAlt="accueil"
                    strength={10}>
                <Background>
                    <TextContainer>
                      <PageElement>La page que vous cherchez n'existe pas...</PageElement>
                      <PageElement>
                        <Link component={RouterLink} to="/">
                          Cliquez ici pour retourner à la page d'accueil
                        </Link>
                      </PageElement>
                    </TextContainer>
                  </Background>
                </Parallax>
              </ThemeProvider>
          </React.Fragment>
      )
  }
}

export default NotFound;